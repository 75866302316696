/*** Guidance banner styling and animation ***/
@keyframes example {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-30px);
  }
}

.rc-header-guidance {
  animation: example 0.5s;
  animation-fill-mode: forwards;
}

/*** CommandBar entry animation ***/
/*** We can't do exit animation because rc-dialog makes display: none ***/
@keyframes onEntry {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.commandbar-opened {
  animation: onEntry 0.1s;
  animation-fill-mode: forwards;
}

@keyframes onSelect {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  50% {
    transform: translate3d(0px, 0px, -0.5px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

.commandbar-selected {
  animation: onSelect 0.4s;
}

@keyframes ScaleEntry {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.commandbar-scale-entry-animation {
  animation: ScaleEntry 0.3s;
  animation-fill-mode: forwards;
}

@keyframes onEntry {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.commandbar-shortcut-tooltip {
  opacity: 0;
  animation: tagEntry 0.2s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes tagExit {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

.commandbar-shortcut-tooltip-remove {
  animation: tagExit 0.2s;
  animation-fill-mode: forwards;
}

.commandbar-shortcut-tooltip,
.commandbar-shortcut-tooltip-remove {
  z-index: 99999999;
  position: absolute;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.8);
  font-family: proxima-nova, sans-serif;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  font-size: 13px;
}

.commandbar-shortcut-tooltip-tag {
  margin-left: 10px;
  padding: 2px 5px;
  border-radius: 2px;
  background-color: grey;
}

#commandbar-launcher {
  animation: 0.25s ease-out scaleIn;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.06);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes growFromBottom {
  0% {
    transform: scale(1, 0.3);
  }

  100% {
    transform: scale(1, 1);
  }
}
